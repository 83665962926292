<template>
  <div class="home">
    <!-- <navigator/>
         <b-breadcrumb :items="breadcrumbs"></b-breadcrumb> -->
      
        <b-row class="m-0">
          <!-- <b-col cols="12" class="p-0"> -->
            <sidebar />
          <b-col class="p-0">
            <navigator/> 
          <b-breadcrumb :items="breadcrumbs"></b-breadcrumb>
            <b-row class="mt-3">
              <b-col cols="12"  v-for="(item, index) in messages" :key ="index">
                <b-alert :show="dismissSecs"
                         dismissible
                         :variant="item.type"
                         @dismiss-count-down="countDownChanged">{{item.message}}</b-alert>
              </b-col>
              <b-col class="p-0 m-0" cols="12">
                <router-view/>
              </b-col>
            </b-row>
            
          </b-col>
        </b-row>
  </div>
</template>

<script>

import notificationService from '@/store/notificationService'
import breadcrumbService from '@/store/breadcrumbService'
import sidebar from '@/components/sidebar.vue'
import navigator from '@/components/navigator.vue'
import {mapActions, mapState} from 'vuex'

export default {
  name: 'Home',
  components:{
    navigator,
    sidebar
  },
  data: () => ({
    dismissSecs: 10,
    dismissCountDown: 0,
        items: [
          {
            text: 'Admin',
            href: '#'
          },
          {
            text: 'Manage',
            href: '#'
          },
          {
            text: 'Library',
            active: true
          }
        ]
  }),
  beforeCreate() {
    // this.$store.commit('setRootData', this.$root.urlConfig)
    this.$store.commit('setApiUrl', this.$root.urlConfig.api_url)
    if (!this.$store.hasModule('notificationService')) {
      this.$store.registerModule('notificationService', notificationService);
    }

    
    if (!this.$store.hasModule('breadcrumbService')) {
        this.$store.registerModule('breadcrumbService', breadcrumbService);
    }
  },
  created(){
    this.clearMessage()
  },
  mounted() {
      this.$store.commit('setApiUrl', this.$root.urlConfig.api_url)
  },
  computed:{
    ...mapState('notificationService', ['messages']),
    ...mapState('breadcrumbService', ['breadcrumbs'])
  },
  methods: {
    ...mapActions('notificationService', ['clearMessage']),
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
      this.dismissCountDown = this.dismissSecs
    },
  },
}
</script>
