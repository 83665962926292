// import Vue from 'vue'
// import Vuex from 'vuex'

export default{
    namespaced: true,
    state:{
        breadcrumbs: []
    },
    mutations:{
        setBreadcrumb: (state, payload) => {
            state.breadcrumbs = payload
        }
    },
    actions: {
    }
}