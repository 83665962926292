<template>
    <div class="sidebar-card">
        <b-sidebar id="sidebar-1" shadow no-header backdrop>
            
            <template #footer="">
                <div class="text-center">
                    <a class="subtitle" href="https://www.cware.co.za" target="_blank">Developed by CWARE</a>
                </div>
            </template>


            <template>
                <div class="p-0">
                    <div class="">
                        <div class="pb-2 pt-5 sidebar-header">
                            <h3 class="text-white text-center">{{username}}</h3>
                            <p class="text-center text-white mb-3">{{userEmail}}</p>
                        </div>   
                <b-list-group-item>
                    <router-link to="/home"> 
                        <b-icon-house-door class="mr-2"></b-icon-house-door>Home
                    </router-link>
                </b-list-group-item>

                
                <b-list-group-item v-if="hasAccess('ACL Triggers')">
                        <router-link to="/acl/triggerSearch"> 
                            <b-icon-list-task class="mr-2"></b-icon-list-task>ACL Triggers
                        </router-link>
                </b-list-group-item>

                
                <b-list-group-item v-if="hasAccess('Self Service Request')">
                        <router-link to="/selfService/search"> 
                            <b-icon-flag class="mr-2"></b-icon-flag>Self Service Request
                        </router-link>
                </b-list-group-item>
                
                <b-list-group-item v-if="hasAccess('Job Cards')">
                        <router-link to="/jobcards/search"> 
                            <b-icon-clipboard class="mr-2"></b-icon-clipboard>Job Cards
                        </router-link>
                </b-list-group-item>

                <b-list-group-item v-if="hasAccess('Quotes')">
                        <router-link to="/quotes/search"> 
                            <b-icon-file-earmark class="mr-2"></b-icon-file-earmark>Quotations
                        </router-link>
                </b-list-group-item>

                <b-list-group-item v-b-toggle.checklists>
                    <b-icon-check-circle class="mr-2"></b-icon-check-circle>Checklists</b-list-group-item>
                <b-collapse id="checklists" accordion="checklists">
                    <b-list-group  v-if="hasAccess('Checklist Management')">
                        <router-link to="/checklist/search"> 
                            <b-list-group-item>Manage Checklist
                            </b-list-group-item> 
                        </router-link>
                    </b-list-group>
                    <b-list-group  v-if="hasAccess('Checklist Scheduling')">
                        <router-link to="/checklist/schedule/search"> 
                            <b-list-group-item>Schedule Checklist
                            </b-list-group-item> 
                        </router-link>
                    </b-list-group>
                    <b-list-group v-if="hasAccess('Complete Checklist')">
                        <router-link to="/checklist/complete"> 
                            <b-list-group-item>Complete a Checklist
                            </b-list-group-item> 
                        </router-link>
                    </b-list-group>
                    <b-list-group v-if="hasAccess('Complete Toolbox Talk')">
                        <router-link to="/checklist/completeToolBox"> 
                            <b-list-group-item>Complete a Toolbox Talk
                            </b-list-group-item> 
                        </router-link>
                    </b-list-group>
                </b-collapse>
                
                <b-list-group-item v-b-toggle.ppeRequest >
                    <b-icon-list-ol class="mr-2"></b-icon-list-ol>PPE Requests</b-list-group-item>
                <b-collapse id="ppeRequest" accordion="ppeRequest">
                    <b-list-group>
                        <router-link to="/ppe/history"> 
                            <b-list-group-item>
                                Submit PPE Request
                            </b-list-group-item> 
                        </router-link>
                    </b-list-group>
                    <b-list-group v-if="hasAccess('PPE Management')">
                        <router-link to="/ppe/current/search">
                            <b-list-group-item>
                                Manage PPE Requests
                            </b-list-group-item>
                        </router-link>
                    </b-list-group>
                    <b-list-group v-if="hasAccess('PPE Management')">
                        <router-link to="/ppe/edit">
                            <b-list-group-item>
                                Edit PPE Request Form
                            </b-list-group-item>
                        </router-link>
                    </b-list-group>
                </b-collapse>

                <b-list-group-item v-b-toggle.timesheets v-if="hasAccess('Timesheet Submission') || hasAccess('Timesheet Submission Management')">
                    <b-icon-inbox class="mr-2"></b-icon-inbox>Timesheets
                </b-list-group-item>
                <b-collapse id="timesheets" accordion="timesheets">
                    <b-list-group>
                        <router-link to="/timesheets/view" v-if="hasAccess('Timesheet Submission')"> 
                            <b-list-group-item>
                                Manage Timesheet
                            </b-list-group-item> 
                        </router-link>
                        <router-link to="/timesheets/submitTimesheetList" v-if="hasAccess('Timesheet Submission')"> 
                            <b-list-group-item>
                                Submit Timesheet
                            </b-list-group-item> 
                        </router-link>
                        <router-link to="/timesheets/listSubmissions" v-if="hasAccess('Timesheet Submission Management')"> 
                            <b-list-group-item>
                                Manage Submissions
                            </b-list-group-item> 
                        </router-link>
                    </b-list-group>
                </b-collapse>
                
                
                <b-list-group-item v-if="hasAccess('Survey Management')">
                        <router-link to="/survey/search"> 
                            <b-icon-clipboard class="mr-2"></b-icon-clipboard>Surveys
                        </router-link>
                </b-list-group-item>


                <!-- <b-list-group-item v-b-toggle.reports> -->
                <b-list-group-item v-b-toggle.reports  v-if="hasAccess('Checklist Reports') || hasAccess('Survey Management') || hasAccess('Quotes Reporting') || hasAccess('Timesheet Group Report') || hasAccess('Timesheet Individual Report')">
                    <b-icon-file-earmark-arrow-down class="mr-2"></b-icon-file-earmark-arrow-down>Reports</b-list-group-item>
                <b-collapse id="reports" accordion="reports">
                  <b-list-group v-if="hasAccess('Survey Management')">
                    <router-link to="/survey/summary">
                      <b-list-group-item>Survey Summary
                      </b-list-group-item>
                    </router-link>
                  </b-list-group>
                    <b-list-group v-if="hasAccess('Checklist Reports')">
                        <router-link to="/complianceReport"> 
                            <b-list-group-item>Compliance Report
                            </b-list-group-item> 
                        </router-link>
                    </b-list-group>
                    <b-list-group  v-if="hasAccess('Checklist Reports')">
                        <router-link to="/checklist/completed">
                            <b-list-group-item>User Completed Checklists
                            </b-list-group-item>
                        </router-link>
                    </b-list-group>
                    <b-list-group  v-if="hasAccess('All Completed Checklist Report')">
                        <router-link to="/allchecklist/completed">
                            <b-list-group-item>All Completed Checklists
                            </b-list-group-item>
                        </router-link>
                    </b-list-group>
                    <b-list-group v-if="hasAccess('Quotes Reporting')">
                        <router-link to="/quotes/summary"> 
                            <b-list-group-item>Quotes Summary
                            </b-list-group-item> 
                        </router-link>
                    </b-list-group>
                    <b-list-group v-if="hasAccess('Timesheet Individual Report')">
                        <router-link to="/timesheets/individualReport"> 
                            <b-list-group-item>Timesheet Individual Report
                            </b-list-group-item> 
                        </router-link>
                    </b-list-group>
                    <b-list-group v-if="hasAccess('Timesheet Group Report')">
                        <router-link to="/timesheets/groupReport"> 
                            <b-list-group-item>Timesheet Group Report
                            </b-list-group-item> 
                        </router-link>
                    </b-list-group>
                     <!-- <b-list-group>
                        <router-link to="/comingsoon"> 
                            <b-list-group-item>Efficiency Report
                            </b-list-group-item> 
                        </router-link>
                    </b-list-group> -->
                </b-collapse>
              
                <b-list-group-item v-b-toggle.admin v-if="hasAccess('Self Service Request Admin') || hasAccess('Manage Products') || hasAccess('Manage Contacts') || hasAccess('Manage Users')">
                    <b-icon-folder2-open class="mr-2"></b-icon-folder2-open>Admin</b-list-group-item>
                    <b-collapse id="admin" accordion="admin">
                    
                        <b-list-group v-if="hasAccess('Self Service Request Admin')">
                        <router-link to="/selfService/search/admin"> 
                            <b-list-group-item>Self Service Request
                            </b-list-group-item> 
                        </router-link>
                        </b-list-group>

                        <b-list-group v-if="hasAccess('Manage Products')">
                        <router-link to="/admin/StockSearch"> 
                            <b-list-group-item>Manage Products
                            </b-list-group-item> 
                        </router-link>
                    </b-list-group>
                  <b-list-group v-if="hasAccess('Manage Users')">
                    <router-link to="/admin/userSearch">
                      <b-list-group-item>Manage Users
                      </b-list-group-item>
                    </router-link>
                  </b-list-group>
                  <b-list-group v-if="hasAccess('Manage SHERQ Mail List')">
                    <router-link to="/admin/sherqMailList">
                      <b-list-group-item>Manage SHERQ Mail List
                      </b-list-group-item>
                    </router-link>
                  </b-list-group>
                  <b-list-group v-if="hasAccess('Manage Contacts')">
                    <router-link to="/contacts/contactSearch">
                      <b-list-group-item>Manage Contacts
                      </b-list-group-item>
                    </router-link>
                  </b-list-group>
                </b-collapse>
                
                <b-list-group-item v-b-toggle.superAdmin v-if="hasAccess('Super Admin')">
                    <b-icon-archive class="mr-2"></b-icon-archive> Super Admin</b-list-group-item>
                <b-collapse id="superAdmin" accordion="superAdmin">
                    <b-list-group>
                        <router-link to="/eventLogger"> 
                            <b-list-group-item @click="changeLocation('/eventLogger')">Event Logger
                            </b-list-group-item> 
                        </router-link>
                    </b-list-group>
                    <b-list-group>
                        <router-link to="/exceptionLogger"> 
                            <b-list-group-item @click="changeLocation('/exceptionLogger')">Exception Logger
                            </b-list-group-item> 
                        </router-link>
                    </b-list-group>
                </b-collapse>
                            
                    </div>

                </div>
            </template>
        </b-sidebar>

    </div>
</template>

<script>
import { mapActions } from 'vuex'
    export default {
        data: () => ({
            location: 'topics',
            username: null,
            userEmail:null,
            selectedEnvironment: 'Testing',
            rights: []
        }),
        created() {
            this.username = localStorage.getItem('user')
            this.userEmail = localStorage.getItem('userEmail')
            this.rights = JSON.parse(localStorage.getItem('screenList'))
        },
        methods: {
            ...mapActions(['logout']),
            hasAccess(screen){
                if (this.rights != null){
                    for (let i = 0; i < this.rights.length; i++) {
                        const element = this.rights[i];
                        if (element.toLowerCase() === screen.toLowerCase()) {
                            return true
                        }
                    }
                }
                return false
            },
            changeEnvironment(){
                switch (this.selectedEnvironment) {
                    case 'Production':
                        this.selectedEnvironment = 'Testing'
                        break;
                    case 'Testing':
                        this.selectedEnvironment = 'Production'
                        break;
                }
            },
            changeLocation(newLocation){
            this.$router.push({path: newLocation})
                this.location = newLocation
            },
            logoutUser(){
                this.$router.push({path: '/'})
                this.logout()
            }
        }
    }
</script>


